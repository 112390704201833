import React from "react";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import ImageGallery from 'react-image-gallery';
import Quote from '../components/Quote'
import {useTranslation} from 'react-i18next'
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google'; 

const Home = (props) => {
        const {t} = useTranslation()
        const demoImgs = [
          {original: "./resources/Banner_blue.png", thumbnail: "./resources/Banner_blue.png"},
          {original: "./demo/11.png", thumbnail: "./demo/11.png"},
          {original: "./demo/12.png", thumbnail: "./demo/12.png"},
          {original: "./demo/3.png", thumbnail: "./demo/3.png"},
          {original: "./demo/8.png", thumbnail: "./demo/8.png"},
          {original: "./demo/1.png", thumbnail: "./demo/1.png"}, 
          
          {original: "./demo/5.png", thumbnail: "./demo/5.png"},
          {original: "./demo/2.png", thumbnail: "./demo/2.png"},
          
          {original: "./demo/4.png", thumbnail: "./demo/4.png"},
          {original: "./demo/6.png", thumbnail: "./demo/6.png"},
          {original: "./demo/7.png", thumbnail: "./demo/7.png"},
          {original: "./demo/9.png", thumbnail: "./demo/9.png"},
          {original: "./demo/10.png", thumbnail: "./demo/10.png"},
          {original: "./demo/13.png", thumbnail: "./demo/13.png"},
          {original: "./demo/14.png", thumbnail: "./demo/14.png"},
          {original: "./demo/15.png", thumbnail: "./demo/15.png"},
        ]
    
   
        return(
            <Row className="below-nav">
                    <Container>
                      <Row className="justify-content-center">
                     
                      </Row>
                        <Row>
                            <Col md={6}>
                            
                                <Card className="try-btn">
                                <Card.Body className="text-center">
                                
                                <span className="customlink bolder blue">{t('hello')}<span className="green"> Sally.</span></span>
                                <br/>
                                {!props.userIsLogged? 
                                    <></>
                                    :
                                    <Link to='/music'>
                                     <img className="image-with-back" src="resources/robot2-unscreen.gif" alt="" width="55" height="55"/>
                                    </Link>}
                                
                                <br/><span className="customlink bolder blue">{t('try-1')}<span className="green"> {props.userIsLogged? t('try-2') : t('try-3')}</span></span>
                                
                                </Card.Body>
                              </Card>
                            <Quote quote={t('quote-kandiskij')} author={"Wassily Kandinskij"} source={t('source-kandiskij')}/>
                            </Col>
                        <Col  md={6}>
                      <ImageGallery items={demoImgs}  slideInterval={5000} slideDuration={2000} showThumbnails="true" thumbnailPosition="bottom" autoPlay="true"/>
                      </Col>
                      </Row>
                    </Container>
                  </Row>)
    
}
export default Home