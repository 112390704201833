import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import UTILS from "../api/utils";

import {useTranslation} from 'react-i18next';
export default function DownloadButton(props){
    const {t} = useTranslation();

    return(
        <Button variant="success" className="no-background download-btn" onClick={()=>UTILS.saveZip("sally_images.zip", props.resources)}>
                <Row>
                    <Col md={2}>
                        <img  src="resources/download.png" alt="" width="35" height="35"/>
                    </Col>
                    <Col md={10}>
                        {t('download-msg')}
                    </Col>
                </Row>
        </Button>
    )
}