import {
    MDBIcon,
    MDBTypography,
  } from "mdb-react-ui-kit";
  import Card from 'react-bootstrap/Card'
  import Row from 'react-bootstrap/Row'
  import React from "react";
  
  export default function BlockquoteWithImage(props) {
    return (
      
              <Card style={{ borderRadius: "15px" }}>
                <Card.Body className="p-5">
                    <Row className="justify-content-center">
                    
                  <div className="text-center mb-4 pb-2 quote-img">
                    <Card.Img
                      src="./resources/colors.png"
                      width="50"
                    />
                  </div>
                  </Row>
                  <Row>
                  <figure className="mb-0 text-center">
                    <MDBTypography blockquote>
                      <p className="pb-3">
                        <MDBIcon fas icon="quote-left text-primary" size="xs" />
                        <span className="lead font-italic bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 50"><path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z"/></svg>
                        {props.quote}
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 50"><path d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z"/></svg>
                        
                        </span>
                        <MDBIcon fas icon="quote-right text-primary" size="xs" />
                      </p>
                    </MDBTypography>
                    <figcaption className="blockquote-footer mb-0">
                      {props.author}, {props.source}
                    </figcaption>
                  </figure>
                  </Row>
                </Card.Body>
              </Card>
    )}