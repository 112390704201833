import React, {useEffect, useState, useRef, useReducer} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ImageGallery from 'react-image-gallery';
import RecordView from '../components/Recorder';
import Alert from 'react-bootstrap/Alert';
import {useTranslation} from 'react-i18next';
import ReactLoading from 'react-loading';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import DownloadButton from "../components/DownloadButton";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Dropdown from 'react-bootstrap/Dropdown';
import API from "../api/api";


const Music = (props) => {

    const colors_to_rgb = {
        red: 'rgba(234,70,70,0.9)',
        blue: "rgba(0,151,255,1)",
        yellow: "rgba(239,237,38,1)",
        white: 'rgba(255,255,255,1)',
        black: 'rgba(40,40,40,0.8)',
        orange: 'rgba(238,148,65,0.9)',
        green: 'rgba(28,152,47,0.8)',
        purple: 'rgba(134, 57, 197, 0.8)'
    }
    // const colors = [
    //     'red',
    //     'blue',
    //     'yellow', 
    //     'white', 
    //     'black', 
    //     'orange',
    //     'green',
    //     'violet' ]

    const id_to_colors = {
        0: "orange",
        1: "green",
        2: "blue",
        3: "purple",
        4: "yellow",
        5: "red",
        6: "black",
        7: "white" }



    const colors_to_emotions = {
        orange: 'joy',
        green: 'sadness',
        blue: 'fear', 
        purple: 'hope',
        yellow: 'anger',
        red: 'happiness',
        black:'compassion',
        white: 'affection'
    }

    const model_names = {
        "SG": "StyleGan3",
        "DF": "Stable Diffusion",
        "DFv2": "MusicToken"
    }

    const [imgs, setImgs] = useState([]);
    const [imgsURLS, setImgsURLS] = useState([]);
    const [recStarted, setRecStarted] = useState(false);
    const [recPaused, setRecPaused] = useState(false);
    const {t} = useTranslation()
    const [colorIdx, setColorIdx] = useState(0)
    const [intervalId, setIntervalId] = useState(undefined)
    const [emotion, setEmotion] = useState(-1)
    const [isLoading, setIsLoading] = useState(false)
    const ImageGalleryContainer = useRef(null);
    const [isDemoMode, setIsDemoMode] = useState(false)
    const [model, setModel] = useState("SG")
    const [style, setStyle] = useState(undefined)
    const [serverErr, setServerErr] = useState(false)
    const [serverErrMsg, setServerErrMsg] = useState(null)
    const [workId, setWorkId] = useState(undefined)
    const [isWorkActive, setIsWorkActive] = useState(false)
    const [timelimit, setTimelimit] = useState(30)

    const blackImg = [
        {original: "./resources/Banner_blue.png", thumbnail: "./resources/Banner_blue.png"}
    ]

    const demoImgs = [
        {original: "./resources/Banner_blue.png", thumbnail: "./resources/Banner_blue.png"},
        {original: "./demo/1.png", thumbnail: "./demo/1.png"}, 
        {original: "./demo/2.png", thumbnail: "./demo/2.png"},
        {original: "./demo/3.png", thumbnail: "./demo/3.png"},
        {original: "./demo/4.png", thumbnail: "./demo/4.png"},
        {original: "./demo/5.png", thumbnail: "./demo/5.png"},
        {original: "./demo/6.png", thumbnail: "./demo/6.png"},
        {original: "./demo/7.png", thumbnail: "./demo/7.png"},
        {original: "./demo/8.png", thumbnail: "./demo/8.png"},
        {original: "./demo/9.png", thumbnail: "./demo/9.png"},
        {original: "./demo/10.png", thumbnail: "./demo/10.png"},
        {original: "./demo/11.png", thumbnail: "./demo/11.png"},
        {original: "./demo/12.png", thumbnail: "./demo/12.png"},
        {original: "./demo/13.png", thumbnail: "./demo/13.png"},
        {original: "./demo/14.png", thumbnail: "./demo/14.png"},
        {original: "./demo/15.png", thumbnail: "./demo/15.png"},
    ]

    useEffect(()=>{
        if (recStarted && isDemoMode){
            imgs.push( {original: "./resources/Banner_blue.png", thumbnail: "./resources/Banner_blue.png"})
            ImageGalleryContainer.current.fullScreen()
        }
    }, [recStarted, isDemoMode])

    useEffect(()=>{
        if(!isLoading && !isWorkActive){
            if(workId !== undefined){
                console.log("Closing work")
                API.closeWork(workId).then((res)=>{setWorkId(undefined); setEmotion(-1)}).catch((err)=>{ setWorkId(undefined); setEmotion(-1)})
            }
        }
    }, [workId, isWorkActive, isLoading])

    useEffect(()=>{
        if(serverErr){
            setIsLoading(false)
            setRecStarted(false)
            if (workId !== undefined)
                API.closeWork(workId).then((res)=>{setWorkId(undefined); setEmotion(-1)}).catch((err)=>{ setWorkId(undefined); setEmotion(-1)})
        }
    }, [serverErr])
    
    const updateImages = function updateImages(imageId, emt){
        
        // console.log(imageId)
        // console.log(emt)
        if (imgs.length == 1 && imgs[0]["original"].includes("black")){
            imgs.pop()
        }
        // img = img.replace("https://sally.linksfoundation.com", "http://localhost:8082")
        API.getImage(workId, imageId).then((url)=>{
            imgsURLS.push({"url": url, "emotion": colors_to_emotions[id_to_colors[emt]]})
            // imgs.push({original: img_url, thumbnail: img_url, description: t(emotions[emt]), originalClass: 'featured-slide', originalTitle: t(emotions[emt]), thumbnailTitle: t(emotions[emt]), thumbnailLabel: t(emotions[emt]) })
            imgs.push({original: url, thumbnail: url, originalClass: 'featured-slide'})
            setImgs(imgs)
            console.log(imgs.length)
            setImgsURLS(imgsURLS)
            // setEmotion(-1)
        }).catch((err)=>{
            if (err.status === 204){
                setServerErrMsg(t("204"))
            }
            else{
                setServerErrMsg(t("500") + " " + err.message)
            }
             setServerErr(true);
        })

        
    }

    

    const isRecStarted = function isRecStarted(val){
        if (val){
            console.log("Cleaning images, setting recStarted")
            setRecStarted(true)
            setImgs([])
            setImgsURLS([])
            setServerErr(false)
            setServerErrMsg(null)
        }
        else{
            setRecStarted(false)
        }
        
    }


    const updateColors = function(){
        setIntervalId(setInterval(()=>{
            setColorIdx(colorIdx => (colorIdx + 1) % Object.values(colors_to_rgb).length)
        }, 3500))
    }


    const stopUpdateColors = function(){
        clearInterval(intervalId)
    }
    return (
        <>
        <Container className="below-nav">
                {serverErr &&  
                    <Row className="justify-content-center">
                        <Alert variant="danger"><b>{t("error")}</b>: {serverErrMsg}</Alert>
                    </Row>}
                <Row className="justify-content-center">       
                    {(!recStarted && !isLoading) && 
                        <Row className="justify-content-center">
                            <Card className="card-initial " style={{marginBottom: '30px'}}>
                                <Card.Body >
                                    <Row className="vertical-center justify-content-center">
                                        <Col md={8} sm={8} lg={8} xl={8} xxl={8} xs={8}>
                                            <Row className="justify-content-center text-center lead" style={{fontWeight: 400}}>
                                                { t('rec-info-1')}
                                            </Row> 
                                            <Row className="justify-content-center text-center lead bold">
                                            {t('rec-info-2')}
                                            </Row>
                                        </Col>
                                        <Col md={2} sm={2} lg={2} xl={2} xxl={2} xs={2} className="justify-content-center">
                                            <img className="image-with-back" src="resources/robot-unscreen.gif" alt="" width="55" height="55"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Alert variant="light" className="text-center" style={{fontSize:"small"}}>
                                            {t("disclaimer-data-msg-1")}<br/>{t("disclaimer-data-msg-2")}
                                        </Alert>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Row>}
                    </Row> 
                    
                    
                     {(recStarted || !isLoading) && <>
                        <RecordView updateImages={updateImages} setRecStarted={isRecStarted} updateColors={updateColors} stopUpdateColors={stopUpdateColors}  setEmotion={setEmotion} setRecPaused={setRecPaused} setIsLoading={setIsLoading} setServerErr={setServerErr} setServerErrMsg={setServerErrMsg} workId = {workId} setWorkId={setWorkId} style={style} model={model} isWorkActive={isWorkActive} setIsWorkActive={setIsWorkActive} timelimit={timelimit}/>
            
                        {/* {recStarted && 
                            <Row  className="justify-content-center" style={{paddingTop: "30px"}}>
                                <InputGroup className="justify-content-center">
                                    <InputGroup.Text  style={{marginRight: "30px",  backgroundColor: "#82c04499"}}><b>Model</b></InputGroup.Text>
                                    <BootstrapSwitchButton
                                        checked={model}
                                        onlabel="SG"
                                        offlabel="DF"
                                        onstyle="info"
                                        offstyle="success"
                                        width={150}
                                        onChange={(checked) => {
                                            setModel(checked)
                                        }}
                                    />
                                </InputGroup>
                            </Row>
                        } */}
                        {/* { recStarted && !model && 
                        <Row  className="justify-content-center" style={{paddingTop: "30px", marginBottom: "30px"}}>
                            <Col className="justify-content-center vw-100" xs={5} md={4} lg={3}>
                            <InputGroup className="justify-content-center">
                                <InputGroup.Text  style={{marginRight: "30px",  backgroundColor: "#82c04499"}}><b>Style</b></InputGroup.Text>
                                <Form.Control size="sm" as="textarea" aria-label="With textarea" value={style} onChange={(e) => {setStyle(e.target.value)}}/>
                            </InputGroup>
                            </Col>
                        </Row>} */}
                        {!recStarted && <>
                            <Row  className="justify-content-center" style={{paddingTop: "30px"}}>
                            <InputGroup className="justify-content-center">
                                    <InputGroup.Text  style={{marginRight: "30px",  backgroundColor: "#82c04499"}}><b>{t('gallery-modality')}</b></InputGroup.Text>
                                        <BootstrapSwitchButton
                                        checked={isDemoMode}
                                        onlabel={t('live-mode')}
                                        offlabel={t('classic-mode')}
                                        onstyle="info"
                                        offstyle="success"
                                        width={150}
                                        onChange={(checked) => {
                                            setIsDemoMode(checked)
                                        }}
                                    />
                                </InputGroup>
                            </Row>

                            <Row  className="justify-content-center" style={{paddingTop: "30px"}}>
                                <InputGroup.Text  style={{marginRight: "30px",  backgroundColor: "#82c04499"}}><b>{t('model')}</b></InputGroup.Text>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {!model ? t('select') : model_names[model]}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setModel("SG")}>{model_names["SG"]}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setModel("DF")}>{model_names["DF"]}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {setModel("DFv2"); setTimelimit(90)}}>{model_names["DFv2"]}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                            </Row>
                        { model === "DF" && 
                        <Row  className="justify-content-center" style={{paddingTop: "30px"}}>
                            <InputGroup.Text  style={{marginRight: "30px",  backgroundColor: "#82c04499"}}><b>{t('style')}</b></InputGroup.Text>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {!style ? t('select') : t(style)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setStyle("abstract")}>{t('abstract')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setStyle("baroque")}>{t('baroque')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setStyle("romanticism")}>{t('romanticism')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
            
                        </Row>}
                        
                        </>

                       }
                    </>
                    }
                    
                    
                    
                <Row className="justify-content-center" style={{paddingTop: '30px'}}>
                    
                    {
                        (!recStarted && isLoading) && <Col >
                        <Card className="card-recording">
                                <Card.Body>
                                    {/* <Row className="justify-content-center lead" style={{fontWeight: 400}}>{t('recording_loading_msg')}....</Row> */}

                                    <Row  className="justify-content-center">
                                    
                                        <Col md={4} className="justify-content-center ">
                                            {/* <Row className="justify-content-center lead bold" > 
                                            It sounds like... <br/> </Row> */}
                                            <div className=" cloud-body">
                                           <p>
                                            <Row className="justify-content-center lead bold lead emotion-txt">
                                            <span style={{fontWeight: 400, fontSize:'large'}}>
                                                {t("drawing-msg-2")}
                                            </span>
                                            </Row>
                                            
                                                <Row className="justify-content-center lead" style={{fontWeight: 400}}>
                                                {
                                                 !recPaused &&  <ReactLoading type={"cylon"} color={emotion === -1 ? colors_to_rgb[Object.keys(id_to_colors)[colorIdx]] : ""} height={'40%'} width={'40%'} />
                                                }
                                                    </Row> 
                                            </p></div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <img src="resources/robot2-unscreen.gif" alt="" width="90" height="90"/>
                                        <img src="resources/painting-unscreen.gif" alt="" width="90" height="90"/>
                                        
                                    </Row>
                                    
                                    
                                    

                                </Card.Body>
                                </Card>
                    </Col>
                    }

                    {recStarted && <Col >
                            <Card className="card-recording">
                                    <Card.Body>
                                        {/* <Row className="justify-content-center lead" style={{fontWeight: 400}}>{t('recording_loading_msg')}....</Row> */}

                                        <Row  className="justify-content-center">
                                        
                                            <Col md={4} className="justify-content-center ">
                                                {/* <Row className="justify-content-center lead bold" > 
                                                It sounds like... <br/> </Row> */}
                                                <div className=" cloud-body">
                                               <p>
                                                <Row className="justify-content-center lead bold lead emotion-txt">

                                                    {
                                                        (recPaused) ? 
                                                            <span  style={{fontWeight: 400, fontSize:'large'}}>
                                                                {t("rec-paused-msg")}
                                                            </span>
                                                        :
                                                        <>
                                                            <span  style={{fontWeight: 400, fontSize:'large'}}>
                                                                {emotion === -1 ? t('loading-emotion') : t('found-emotion')}
                                                            </span>
                                                            <span style={emotion === -1 ? {fontWeight: 600, fontSize:'x-large', color:colors_to_rgb[Object.values(id_to_colors)[colorIdx]]} : {fontWeight: 600, fontSize:'x-large', color:colors_to_rgb[Object.values(id_to_colors)[emotion]]}}>
                                                                &nbsp;{emotion === -1 ? t(Object.values(colors_to_emotions)[colorIdx]) : t(colors_to_emotions[id_to_colors[emotion]])}
                                                            </span>
                                                                {
                                                                    emotion !== -1 ? 
                                                                    <span style={{fontWeight: 400, fontSize:'large'}}>
                                                                        {t("drawing-msg")}
                                                                    </span>: <></>
                                                                }
                                                        </>
                                                    }
                                                    
                                                </Row>
                                                
                                                    <Row className="justify-content-center lead" style={{fontWeight: 400}}>
                                                    {
                                                     !recPaused &&  <ReactLoading type={"cylon"} color={emotion === -1 ? colors_to_rgb[Object.values(id_to_colors)[colorIdx]] : ""} height={'40%'} width={'40%'} />
                                                    }
                                                        </Row> 
                                                </p></div>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <img src="resources/robot2-unscreen.gif" alt="" width="90" height="90"/>
                                            <img src="resources/painting-unscreen.gif" alt="" width="90" height="90"/>
                                            
                                        </Row>
                                        
                                        
                                        

                                    </Card.Body>
                                    </Card>
                        </Col> 
                    }
                                                   
                    {/* {imgs.length < 1   ? 
                        <>
                        {
                            (!recStarted && !isLoading) &&
                            
                            <Col md={8}>
                                <ImageGallery ref={ImageGalleryContainer} items={demoImgs}  showThumbnails="true" showBullets="true" thumbnailPosition="bottom" autoplay="true"/>
                            </Col>
                        }
                        </>
                            : 
                        <Col md={8} className="justify-content-center"> 
                            <Row className="justify-content-center">
                                <ImageGallery ref={ImageGalleryContainer} items={imgs} showThumbnails="true" startIndex={imgs.length - 1} showNav="false" />
                            </Row>
                            <Row className="justify-content-center" style={{marginTop: "30px"}}>
                                {recBlobURL && <DownloadButton resources={[...imgsURLS, recBlobURL]}/>}
                            </Row>
                        </Col> } */}

                        
                    <Col md={8} className={imgs.length >= 1 ? "justify-content-center" : ((recStarted && !isDemoMode) || isLoading) && "not-visible"}>
                            {!recStarted && !isLoading && imgs.length >= 1 &&
                                    <Row className="justify-content-center" style={{marginTop: "30px", marginBottom: "30px"}}>
                                    <DownloadButton resources={[...imgsURLS]}/>
                                    </Row>
                                }
                                {console.log(imgs.length)}{console.log(imgs)}
                            <ImageGallery lazyLoad={true} ref={ImageGalleryContainer} items={imgs.length >=1 ? imgs : (isDemoMode && recStarted)? blackImg :  demoImgs} startIndex={imgs.length >=1 ? imgs.length - 1 : 0} showThumbnails="true" showNav="false"/>
                        
                        

                    </Col>


                      
                        
                        
            </Row> 
            </Container>
        </>)
}


// class Music extends React.Component{
    
//     constructor(props){
//         super(props)
//         this.state = { 
//             imgs: new Array(),
//             recStarted: false
//         }
//         this.updateImages = this.updateImages.bind(this) 
//         this.setRecStarted = this.setRecStarted.bind(this)

//         this.demoImgs = [
//             {original: "./demo/1.png", thumbnail: "./demo/1.png"}, 
//             {original: "./demo/2.png", thumbnail: "./demo/2.png"},
//             {original: "./demo/3.png", thumbnail: "./demo/3.png"},
//             {original: "./demo/4.png", thumbnail: "./demo/4.png"},
//             {original: "./demo/5.png", thumbnail: "./demo/5.png"},
//             {original: "./demo/6.png", thumbnail: "./demo/6.png"},
//             {original: "./demo/7.png", thumbnail: "./demo/7.png"},
//             {original: "./demo/8.png", thumbnail: "./demo/8.png"}
//         ]

//     }
//     setRecStarted(val){
//         this.setState({recStarted: val})
//     }
//     updateImages = function updateImages(img){
//         let imgs = this.state.imgs
//         // imgs.push(img)
//         imgs.push({original: img, thumbnail: img})
//         this.setState({imgs: imgs})
//     }

//     render(){
//         const {t} = useTranslation()
//         return(
//             <>
//                 <Row className="below-nav justify-content-center">
//                     <Col>
//                         <RecordView updateImages={this.updateImages} setRecStarted={this.setRecStarted} />
//                     </Col>
                
//                     <Col xs={7}>
//                         {this.state.imgs.length >= 1   ? 
//                         <ImageGallery items={this.state.imgs} showThumbnails="true" startIndex={this.state.imgs.length - 1} showNav="false" /> : 
//                         <>{this.state.recStarted ? <><Alert variant="info" className="w-50">
//                         {t('recording_loading_msg')}.... <Spinner size="sm" animation="border"/></Alert></> : 
//                               <ImageGallery items={this.demoImgs}  showThumbnails="true" showBullets="true" thumbnailPosition="bottom"/>
                            
//                         }</>
//                         }
//                     </Col>
//                 </Row>
//             </>)
//     }
// }

export default Music