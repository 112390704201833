import React, {useEffect, useState, useRef, useReducer} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import ImageGallery from 'react-image-gallery';
import API from "../api/api";
import Work from "../components/Work"
// import Pagination from 'react-bootstrap/Pagination';
import Pagination from "../components/Pagination";
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import {useTranslation} from 'react-i18next'


const SortButton = (props) => {
    const {t} = useTranslation()
    return (
        <>
            <Dropdown data-bs-theme="green">
                <Dropdown.Toggle id="dropdown-button-green-example1" variant="success">
                    {t('sort')} {t(props.orderCriterion)}, {props.orderAscending ? t("asc") : t("desc")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
            <Dropdown.Item onClick={() => props.setOrderCriterion("name")}>
                {props.orderCriterion==="name" ? <b>{t("name")}</b> : <>{t("name")}</>}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.setOrderCriterion("timestamp")}>
                {props.orderCriterion==="date" ? <b>{t("date")}</b> : <>{t("date")}</> }
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => props.setOrderAscending(true)}>
                {props.orderAscending ? <b>{t("asc")}</b> : <>{t("asc")}</>}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.setOrderAscending(false)}>
                {!props.orderAscending ? <b>{t("desc")}</b> : <>{t("desc")}</>}
            </Dropdown.Item>
            </Dropdown.Menu>
      </Dropdown>
        </>
    )
}


const PersonalPage = (props) => {
    const {t} = useTranslation()
    const [works, setWorks] = useState([])
    const [serverErr, setServerErr] = useState(false)
    const [serverErrMsg, setServerErrMsg] = useState(null)
    const [worksPerPage, setWorksPerPage] = useState(6)
    const [numWorks, setNumWorks] = useState(0)
    const [currentWorks, setCurrentWorks] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [orderCriterion, setOrderCriterion] = useState("date")
    const [orderAscending, setOrderAscending] = useState(false)
    const [filterLive, setFilterLive] = useState(false)
    const [unfilteredWorks, setUnfilteredWorks] = useState([])

    const onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;
        setCurrentPage(currentPage)
        setCurrentWorks(works.slice((currentPage-1)*worksPerPage, currentPage*worksPerPage))
    }

    const modifyWorkName = (work_id, new_name) => {
        setWorks(works.map((work)=>{
            if (work.work_id === work_id){
                work.work_name = new_name
        }}))
    }

    const updateWorks = () => {
        API.getWorks().then((response)=>{
            const works = response.works
            works.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
            setWorks(works)
            setServerErr(false)
            setServerErrMsg(null)
            setFilterLive(false)
            // console.log(works.length)
            setNumWorks(works.length)
            setCurrentWorks(works.slice(0, worksPerPage))

        }).catch((err)=>{
            if(err.status === 401){
                props.logout()
            }else{
                setServerErr(true)
                setServerErrMsg(err.message)
            }
        })
    }

    useEffect(()=>{
        console.log(orderCriterion, orderAscending)
        if (orderCriterion === "name"){
            if (orderAscending){
                works.sort((a, b) => (a.work_name > b.work_name) ? 1 : -1)
            }else{
                works.sort((a, b) => (a.work_name < b.work_name) ? 1 : -1)
            }
        }
        else if (orderCriterion === "date"){
            if (orderAscending){
                works.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)
            }else{
                works.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
            }
        }
        setWorks(works)
        setCurrentWorks(works.slice((currentPage-1)*worksPerPage, currentPage*worksPerPage))
    }, [orderCriterion, orderAscending])


    useEffect(()=>{
        setCurrentWorks(works.slice((currentPage-1)*worksPerPage, currentPage*worksPerPage))
    }, [works])
    
    useEffect(()=>{
        if(filterLive){
            setUnfilteredWorks(works)
            const filtered = works.filter((work)=>work.active)
            setWorks(filtered)
            setCurrentWorks(filtered.slice((currentPage-1)*worksPerPage, currentPage*worksPerPage))
            setNumWorks(filtered.length)
        }
        else{
            setWorks(unfilteredWorks)
            setCurrentWorks(unfilteredWorks.slice((currentPage-1)*worksPerPage, currentPage*worksPerPage))
            setNumWorks(unfilteredWorks.length)
        }
    }, [filterLive])


    useEffect(()=>{
        updateWorks()
    }, [])

    return (
        <>
        <Container className="below-nav"> 
        <Row>
            <Col >
                <span><h1>{t("gallery")}</h1></span> 
            </Col>
            <Col  className="justify-content-start align-items-center" style={{"display": "flex"}} >
                <Button variant="success" className="update-btn" onClick={() => updateWorks()}><img  src={"./resources/refresh-page-option.png"} alt="" width="20" height="20"/></Button>  
            </Col>
            <Col className="justify-content-end align-items-center" style={{"display": "flex"}} >
                <Form>
                    <Form.Check  size={"lg"} type="switch" id="custom-switch" label={"Live"} onChange={() => setFilterLive(!filterLive)} checked={filterLive} lab/>
                </Form>
            </Col>
            <Col  className="justify-content-end align-items-center" style={{"display": "flex"}}>
                <SortButton orderAscending={orderAscending} orderCriterion={orderCriterion} setOrderAscending={setOrderAscending} setOrderCriterion={setOrderCriterion}/>
            </Col>
        </Row>
        {serverErr? <Row className="justify-content-center"><Alert variant="danger">{serverErrMsg}</Alert></Row>: <></>}
            <Row className="justify-content-center">
                {currentWorks.map((work, index)=>{
                    // console.log(work)
                    return <Work key={work.work_id} work={work} setServerErr={setServerErr} setServerErrMsg={setServerErrMsg} modifyWorkName={modifyWorkName} updateWorks={updateWorks}/>
                    })
                }
            </Row>
            <Row className="justify-content-center">
            {numWorks > 0 ? <Pagination totalRecords={numWorks} pageLimit={worksPerPage} pageNeighbours={3} onPageChanged={onPageChanged} /> : <></>}
                {/* <Pagination size="lg">
                    {pages}
                    <Pagination.Next onClick={() => setActivePage(activePage + 1)} />
                </Pagination> */}
            </Row>
        </Container>
        </>
    )

}

export default PersonalPage