import JSZip from "jszip";
import { saveAs } from 'file-saver';
const saveZip = (filename, urls) => {
    
    if(!urls) return;

    const zip = new JSZip();
    // const folder = zip.folder("files"); // folder name where all files will be placed in 

    
    // console.log(urls.length)
    urls.forEach((value, idx) => {
        let emt = ""
        let url = value
        
        url = value["url"]
        emt = value["emotion"]
        

        const blobPromise = fetch(url).then((r) => {
            // console.log(url)
            // console.log(idx)
            if (r.status === 200) return r.blob();

            return Promise.reject(new Error(r.statusText));
        });
        let name = "img_" + idx.toString() + "_" + emt + "_" + url.substring(url.lastIndexOf("/") + 1) + ".png";

        zip.file(name, blobPromise);
    });

    zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));

};

const base64ToBlob = function(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: 'image/jpeg' });
  }

const UTILS = {
    saveZip,
    base64ToBlob
   };
export default UTILS;