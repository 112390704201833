import './App.css';
import React, {Suspense} from 'react';
import Header from './components/Header'
import { Routes, Navigate, Route } from "react-router-dom";
import Music from './pages/Music'
import Home from './pages/Home'
import Login from './pages/Login';
import API from './api/api';
import './i18n'
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Footer from './components/Footer';
import Container from 'react-bootstrap/Container'
import PersonalPage from './pages/Personal';
import { redirect } from "react-router-dom";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

class App extends React.Component{

  constructor(props){
    super(props)
    // console.log(props.location)
    this.state = {user: null, userIsLogged: false, loginError: false}
    this.getUser = this.getUser.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.logout = this.logout.bind(this)
    this.setLoginError = this.setLoginError.bind(this)
    this.setLoginSuccessful = this.setLoginSuccessful.bind(this)
    this.setLogout = this.setLogout.bind(this)
  }

  getUser = () => {
    API.getUser().then((user)=>{
      this.setState({user: user, userIsLogged: true, loginError: false})
    }).catch((err)=>{
      this.setState({user: null, userIsLogged: false, loginError: true})
    })
  }

  getAccessToken = (googleIdToken) => {
    API.requestAccessTokenFromGoogleIdToken(googleIdToken.credential).then((user)=>{
      this.setLoginSuccessful(user)
    }).catch((err)=>{
      this.setLoginError()
    })
  }


  logout = () => {
    API.logout().then(()=>{
      this.setLogout()
    }).catch(()=>{

    })
  }

  setLoginError = () => {
    this.setState({user: null, userIsLogged: false, loginError: true})
  }
  setLoginSuccessful = (user) => {
    this.setState({user: user, userIsLogged: true, loginError: false})
  }
  setLogout = () => {
    this.setState({user: null, userIsLogged: false, loginError: false})
  }


  async componentDidMount(){
    API.getUser().then((user)=>{
      // console.log(user)
      this.setLoginSuccessful(user)
    }).catch(()=>{
      this.setLogout()
    })
  }

  

  render(){
    return (
      <>
      <Suspense fallback={null}>
      
      <Header path={this.props.router.location.pathname} user={this.state.user} userIsLogged={this.state.userIsLogged} getAccessToken={this.getAccessToken} getUser={this.getUser} setLoginError={this.setLoginError} logout={this.logout}/>
      <Container className="body">
        <Routes>

        <Route exact path="/home" element={<Home user={this.state.user} userIsLogged={this.state.userIsLogged} getAccessToken={this.getAccessToken} getUser={this.getUser} setLoginError={this.setLoginError} />}/>
        { this.state.userIsLogged ? <Route exact path="/music" element={<Music user={this.state.user} userIsLogged={this.state.userIsLogged}/>}/>:
            <Route path="/" element={<Navigate to="/home" />}/>}      
          
          { this.state.userIsLogged ? 
            <Route exact path="/personal" element={<PersonalPage user={this.state.user} userIsLogged={this.state.userIsLogged} logout={this.logout}/>}/>
            :
            <Route path="/" element={<Navigate to="/home" />}/>
          }
          {/* <Route exact path="/login" element={<Login/>}/> */}
          <Route
            path="*"
            element={<Navigate to="/home" />}/>

        </Routes>
      </Container>
      <Footer/>
      </Suspense>
      </>
      
    );
  }
}


export default withRouter(App);
