import React, { useState } from 'react';
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';

import {useTranslation} from 'react-i18next';
import API from '../api/api';
import { useGoogleLogin } from '@react-oauth/google';



function Login() {

  // const GLogin = useGoogleLogin({onSuccess: codeResponse => console.log(codeResponse), onError: codeResponse => console.log(codeResponse)})
  const [justifyActive, setJustifyActive] = useState('tab1');;
  const {t} = useTranslation()
  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50 below-nav">
      
      <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
            {t("login")}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
            {t("register")}
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>

        <MDBTabsPane show={justifyActive === 'tab1'}>

          <MDBInput wrapperClass='mb-4' label='Email' id='form1' type='email'/>
          <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password'/>

          {/* PASSWORD DIMENTICATA
          
          <div className="d-flex justify-content-between mx-4 mb-4">
            <a href="!#">{t("forgot-psw")}</a>
          </div> */}

          <MDBBtn className="mb-4 w-100 btn-green">{t("sign-in")}</MDBBtn>
          {/* <p className="text-center">{t("not-member")} <a href="#!">{t("register")}</a></p> */}

        </MDBTabsPane>

        <MDBTabsPane show={justifyActive === 'tab2'}>

          
           <MDBInput wrapperClass='mb-4' label='Username' id='form1' type='text'/>
          <MDBInput wrapperClass='mb-4' label='Email' id='form1' type='email'/>
          <MDBInput wrapperClass='mb-4' label='Password' id='form1' type='password'/>

          {/* CHECKBOX PER TERMINI E CONDIZIONI
          
          <div className='d-flex justify-content-center mb-4'>
            <MDBCheckbox name='flexCheck' id='flexCheckDefault' label='I have read and agree to the terms' />
          </div> */}
          
          <MDBBtn className="mb-4 w-100 btn-green" onClick={() => {}}>{t("sign-up")}</MDBBtn>

        </MDBTabsPane>

      </MDBTabsContent>
      <MDBBtn className="mb-4 w-100 btn-green" onClick={()=>{}}>LOGIN WITH GOOGLE</MDBBtn>
    </MDBContainer>
    
  );
}

export default Login;