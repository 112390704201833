import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import i18next from 'i18next';
import { GoogleLogin } from '@react-oauth/google'; 

//funzione per renderizzare header -> se sono loggato mostro link per storico e nuova prenotazione
//viene passato il path corrente per poter visualizzare correttamente l'opzione di nuova prenotazione o di accesso allo storico senza renderle ridondanti

const getLink = (current_page) => {
    if (current_page == "/home"){
        return "/music"
    }
    else{
        return "/home"
    }
}


const getImage = (current_page) => {
    if (current_page == "/home"){
        return "resources/robot-unscreen.gif"
    }
    else{
        return "/resources/photo-gallery-unscreen.gif"
    }
}

const Header=(props)=>{
    const responseMessage = (response) => {
        // console.log(response);
        // console.log(response.tokenId)

      };
      const errorMessage = (error) => {
        //   console.log(error);
      };
    const {t} = useTranslation()
    const { i18n } = useTranslation('home');
    const language = i18n.language
    return(
      <Navbar bg="light"  expand="sm" fixed="top" className='navbar-color' >
            <Navbar.Brand>
                <img src="resources/logo5.png" alt="sally"  width="60" height="60" className="d-inline-block align-top"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav"> 
                <Nav className="me-auto">
                    {props.path !== "/home" ?
                        <Nav.Item className="ml-auto navbar-nav-flag">
                            <Row className='tooltip justify-content-center'>
                                <Col>
                                    <Link to="/home"><img src="resources/home.png" alt="" width="40" height="40"/></Link>
                                </Col>
                                <Col className='tooltiptext nav-msg'>
                                <span className="customlink bolder blue">Home</span>
                                </Col>
                            </Row>
                    </Nav.Item> : <></>}
                    
                    {props.userIsLogged ? 
                        <>
                        {props.path !== "/personal" ? 
                            <Nav.Item className="ml-auto navbar-nav-flag">
                            <Row className='tooltip justify-content-center'>
                                <Col>
                                    <Link to="/personal"><img src="resources/photo-gallery.png" alt="" width="40" height="40"/></Link>
                                </Col>
                                <Col className='tooltiptext nav-msg'>
                                <span className="customlink bolder blue">{t("personal-page")}</span>
                                </Col>
                            </Row>
                        </Nav.Item> : <></> }
                    {props.path!== "/music" ? <Nav.Item className="ml-auto navbar-nav-flag">
                            <Row className='tooltip justify-content-center'>
                                <Col>
                                <Link to="/music"><img src="resources/robot.png" alt="" width="40" height="40"/></Link>
                                </Col>
                                <Col className='tooltiptext nav-msg'>
                                    <span className="customlink bolder blue">{t('sally_link')} </span>
                                    <span className="customlink green bold">Sally</span>
                                </Col>
                            </Row>
                        </Nav.Item> : <></> }
                        </> : <></>}
                    
                    
                    
                    </Nav>
                
                    <Nav className="ml-auto">
                    <Nav.Item className="ml-auto navbar-nav-flag">
                        { !props.userIsLogged ? 
                        <GoogleLogin className="mb-4 w-100 btn-green" cookiePolicy={'single_host_origin'} onSuccess={props.getAccessToken} onError={props.setLoginError} locale={language === "en" ? "en_US" : "it_IT"}/>
                            // <Link to={"/login"}>
                            //     <img src={"resources/user_blue.png"} alt="" width="24" height="24" className="usr-icon-with-border"/>
                            // </Link>
                            :

                            
                            <Nav.Item className="ml-auto navbar-nav-flag">
                                <Dropdown as={ButtonGroup}>
                                    <Button  id="login-btn" variant="success" disabled="True" style={{    opacity: 1}}><Link className='white-link' to="/personal"><img height="25" className='usericon invert' src="resources/user.png"/>{props.user.username}</Link></Button>

                                    <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                                    <Dropdown.Menu>
                                        {/* { props.path !== "/music"?
                                            <Dropdown.Item><Link className='black-link' to="/music">{t("sally_link")} Sally </Link></Dropdown.Item>
                                            :
                                            <Dropdown.Item><Link className='black-link' to="/home">Home</Link></Dropdown.Item>
                                        }
                                        {props.path !== "/personal"?
                                            <Dropdown.Item><Link className='black-link' to="/personal">{t('personal-page')}</Link></Dropdown.Item> 
                                            : 
                                            <Dropdown.Item><Link className='black-link' to="/home">Home</Link></Dropdown.Item> 
                                        } */}
                                        
                                        
                                        <Dropdown.Item style={{color: "red"}} onClick={() => props.logout()}>{t('logout')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            
                            </Nav.Item>
                        
                            
                        }
                    </Nav.Item>
                    <Nav.Item className="ml-auto navbar-nav-flag">
                    <Dropdown className='justify-content-end drop-language'>
                    <Dropdown.Toggle variant="light"  id="dropdown-basic" className='drop-flag'><span className={language === "en" ? "fi fi-gb" : "fi fi-it"}/></Dropdown.Toggle>
                    <Dropdown.Menu className='dropdown-menu-center menu-flag'>
                    <Dropdown.Item className="flag"><Button variant='light' onClick={()=> i18next.changeLanguage('en')}><span className="fi fi-gb"/></Button> </Dropdown.Item>
                    <Dropdown.Item className="flag"><Button variant='light' onClick={()=> i18next.changeLanguage('it')}><span className="fi fi-it" /></Button>  </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                    </Nav.Item>
                    </Nav>
                    </Navbar.Collapse>
                
                
                
            
      </Navbar>
);}

export default Header;